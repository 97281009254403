<template>
  <div class="data-table" v-loading="loading" element-loading-text="Loading...">
    <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="tableData" class="table-data"
              :row-style="{ height: '57px' }">
      <el-table-column prop="firmwareName" :label="$t('ProductManage.ConfigManage.firmwareName')"
      >
        <template #default="scope">
          <el-tooltip effect="dark" placement="top-start" :content="scope.row.firmwareName">
            <span>{{ scope.row.firmwareName }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="firmwareDescription"
                       :label="$t('ProductManage.ConfigManage.firmwareDescription')">
        <template #default="scope">
          <el-tooltip effect="dark" placement="top-start" :content="scope.row.firmwareDescription">
            <span>{{ scope.row.firmwareDescription }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="firmware" :label="$t('ProductManage.ConfigManage.firmware')">
        <template #default="scope">
          <el-tooltip effect="dark" placement="top-start" :content="scope.row.fileName">
            <!-- 使用el-link组件创建下载链接 -->
            <el-link
                :underline="false"
                @click="downloadFirmware(scope.row.file_url)"
            >
              <span class="link-text-style">{{ truncatedText(scope.row.fileName) }}</span>
            </el-link>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="compatibleModel" :label="$t('ProductManage.ConfigManage.compatibleModel')">
        <template #default="scope">
          <el-tooltip effect="dark" placement="top-start" :content="scope.row.compatibleModel">
            <span>{{ scope.row.compatibleModel }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="compatibleOrganization"
                       :label="$t('ProductManage.ConfigManage.compatibilityOrganization')">
        <template #default="scope">
          <el-tooltip effect="dark" placement="top-start" :content="scope.row.compatibleOrganization">
            <span>{{ scope.row.compatibleOrganization }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$t('ProductManage.ConfigManage.operation')">
        <template v-slot="scope">
          <el-button link @click="editMotorFirmware(scope.row)"><span
              class="link-text-style">{{ $t('ProductManage.ConfigManage.edit') }}</span></el-button>
          <el-popconfirm :title=" $t('ProductManage.ConfigManage.sureDelete')" placement="top"
                         @confirm="deleteMotorFirmWare(scope.row)"
                         :confirm-button-text="$t('ProductManage.ConfigManage.confirm')"
                         :cancel-button-text=" $t('ProductManage.ConfigManage.cancel')" confirm-button-type="success"
                         width="250"
          >
            <template #reference>
              <el-button link><span class="link-text-delete">{{ $t('ProductManage.ConfigManage.delete') }}</span>
              </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>

      <template v-slot:empty>
        <EmptySlot/>
      </template>

    </el-table>
  </div>
  <!--编辑弹框-->
  <el-dialog
      style="text-align: left;"
      :title="$t('ProductManage.ConfigManage.edit')"
      v-model="addDialogVisible"
      width="600"
      @close="handleDialogClose"
  >
    <hr class="top-separator"/>

    <!-- 弹窗内容 -->
    <el-form :model="firmwareForm" ref="firmwareForm" :rules="firmwareFormRules" label-width="300px"
             label-position="top">
      <el-row :gutter="20">
        <el-col :span="24">

          <el-form-item :label="$t('ProductManage.ConfigManage.compatibilityOrganization')"
                        prop="compatibleOrganization">
            <el-tree-select
                v-model="firmwareForm.compatibleOrganization"
                :data="organizationOptions"
                :props="{ label: 'label', children: 'children' }"
                show-checkbox default-expand-all
                multiple filterable
                check-strictly
                check-on-click-node
                :placeholder="$t('ProductManage.ConfigManage.compatibilityOrganizationPlaceholder')"
                :render-after-expand="false"
                clearable
                style="width: 100%"
            />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="compatibleModel">
            <label> <span style="color: rgb(245,108,108)">*</span>{{ $t('ProductManage.adaptationModel') }}</label>
            <el-select
                v-model="firmwareForm.compatibleModel"
                multiple filterable
                :placeholder="$t('ProductManage.ConfigManage.compatibleModelPlaceholder')"
                style="width: 100%"
            >
              <el-option
                  v-for="item in compatibleModelsOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <!-- 新增列 ，固件版本号后两位-->
        <el-col :span="24">
          <el-form-item :label="$t('ProductManage.ConfigManage.firmwareVersionLastTwoDigits')" prop="firmwareVersionLastTwoDigits">
            <el-input v-model="firmwareForm.firmwareVersionLastTwoDigits"
                      :placeholder="$t('ProductManage.ConfigManage.firmwareVersionLastTwoDigitsPlaceholder')"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item :label="$t('ProductManage.ConfigManage.firmwareName')" prop="firmwareName">
            <el-input v-model="firmwareForm.firmwareName"
                      :placeholder="$t('ProductManage.ConfigManage.firmwareNamePlaceholder')"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item :label="$t('ProductManage.ConfigManage.firmwareDescription')" prop="firmwareDescription">
            <el-input type="textarea" v-model="firmwareForm.firmwareDescription"
                      :placeholder="$t('ProductManage.ConfigManage.firmwareDescriptionPlaceholder')"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">

          <el-form-item label="" prop="fileList" style="margin-top: 7px;margin-bottom: 5px;">
            <el-upload v-model:file-list="firmwareForm.fileList" class="upload-demo"
                       :before-upload="handleBeforeUpload"
                       :limit="1"
                       :on-change="handleChange" accept=".bin">
              <el-button class="upload-button">
                <el-icon>
                  <Upload/>
                </el-icon>
                {{ $t('ProductManage.ConfigManage.uploadFirmware') }}
              </el-button>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <hr class="dialog-separator"/>

    <div class="dialog-footer">
      <el-button @click="addDialogVisible = false" class="custom-reset-button">
        {{ $t('ProductManage.ConfigManage.cancel') }}
      </el-button>
      <el-button class="button-background-color" @click="savedMotorFileWareData" :loading="isUploading"> {{ $t('ProductManage.ConfigManage.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  showFirmware,updateFirmware,uploadFileData,
} from "@/api/api";
import {Upload} from "@element-plus/icons-vue";
import resizeMixin from "@/mixins/resizeMixin";
import dialogMixin from "@/mixins/dialogMixin";
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  components: {EmptySlot, Upload},
  data() {
    return {
      addDialogVisible: false,
      isUploading: false, // 添加上传状态变量
      firmwareForm: {
        firmwareName: '',
        compatibleModel: [],
        compatibleOrganization: [],
        firmwareDescription: '',
        fileList: [],
        uploadedFile: '', // 用于存储单个文件标识符
        fileSize: 0,
        firmwareVersionLastTwoDigits: '' // 新增字段
      },
      firmwareFormRules: {
        firmwareName: [
          {required: true, message: this.$t('ProductManage.firmwareNameRequired'), trigger: 'change'},
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.firmwareNamePattern'),
            trigger: 'blur',
          },
        ],
        compatibleModel: [
          {required: true, message: this.$t('ProductManage.compatibleModelRequired'), trigger: 'change'},
        ],
        compatibleOrganization: [
          {required: true, message: this.$t('ProductManage.compatibleOrganizationRequired'), trigger: 'change'},
        ],
        firmwareVersionLastTwoDigits: [ // 新增校验规则
          { required: true, message: this.$t('ProductManage.ConfigManage.firmwareVersionLastTwoDigitsRequired'), trigger: 'blur' },
          {
            pattern: /^\d{2}$/,
            message: this.$t('ProductManage.ConfigManage.firmwareVersionLastTwoDigitsPattern'),
            trigger: 'blur',
          },
        ],

      },
      attrs: {
        multiple: true,
      },

    };
  },
  props: {
    tableData: Array, // 电机数据
    loadData: {
      type: Function,
      required: true,
    },
    loading: Boolean,  // 接收 loading 状态
    compatibleModelsOptions: Array,
    organizationOptions: Array
  },
  emits: ['delete-motor-firmware'],
  mixins: [resizeMixin, dialogMixin],
  methods: {
    handleDialogClose() {
      this.resetForm();
    },
    downloadFirmware(downloadLink) {
      // 创建一个隐藏的 <a> 标签
      const link = document.createElement('a');
      link.href = downloadLink;
      link.setAttribute('download', ''); // 尝试强制下载，但效果依赖于文件类型和服务器配置
      document.body.appendChild(link);
      link.click(); // 模拟点击
      document.body.removeChild(link); // 移除临时添加的 <a> 标签
    },
    async editMotorFirmware(motorfirmware) {
      this.addDialogVisible = true;

      try {
        const fileId = motorfirmware.firmware_id;
        const firmwareInformation = await showFirmware(fileId);
        this.firmwareForm.firmwareId = firmwareInformation.data.id;
        this.firmwareForm.firmwareName = firmwareInformation.data.name;
        this.firmwareForm.compatibleModel = firmwareInformation.data.productModels.map(model => model.id);
        this.firmwareForm.compatibleOrganization = firmwareInformation.data.organizations.map(model => model.id);
        this.firmwareForm.firmwareDescription = firmwareInformation.data.desc;
        this.firmwareForm.uploadedFile = firmwareInformation.data.file;
        this.firmwareForm.firmwareVersionLastTwoDigits = firmwareInformation.data.version || ''; // 确保后端返回
        // 处理文件URL展示
        const fileUrl = firmwareInformation.data.file_url; // 假设这是你从后端获取到的文件URL
        const fileName = firmwareInformation.data.file_name; // 从URL中提取文件名

        this.firmwareForm.fileList = [{
          name: fileName, // 文件名
          url: fileUrl, // 文件URL
          status: 'success' // 标记为已上传
        }];
      } catch (error) {
        this.$message.error(this.$t('ProductManage.ConfigManage.editFailed'));
      }
    },
    deleteMotorFirmWare(motor) {
      this.$emit('delete-motor-firmware', motor);
    },
    resetForm() {
      this.$refs.firmwareForm.resetFields();
    },
    async savedMotorFileWareData() {
      await this.$refs.firmwareForm.validate(async valid => {
        if (valid) {
          try {
            // 检查是否已加载编辑数据
            if (!this.firmwareForm) {
              this.$message.error('motorfirmware form data is not loaded.');
              return;
            }
            this.isUploading = true; // 在提交时设置为加载状态
            // 调用更新接口，传递 file_id 和编辑后的 formData
            const firmware_id = this.firmwareForm.firmwareId; // 请替换成你实际的 file_id
            const formData = {
              name: this.firmwareForm.firmwareName,
              model_ids: this.firmwareForm.compatibleModel,
              organization_ids: this.firmwareForm.compatibleOrganization,
              desc: this.firmwareForm.firmwareDescription,
              file: this.firmwareForm.uploadedFile,
              file_name: this.firmwareForm.fileList[0].name,
              file_size: this.firmwareForm.fileSize,
              version: this.firmwareForm.firmwareVersionLastTwoDigits // 新增字段
            };
            await updateFirmware(firmware_id, formData);
            this.$message.success(this.$t('ProductManage.ConfigManage.editSuccess'));
            // 数据保存成功后，关闭编辑弹框
            this.addDialogVisible = false;
            await this.loadData();
          } catch (error) {
            // 处理错误，可以在界面上显示错误信息等
            this.$message.error(this.$t('ProductManage.ConfigManage.editFailed'));
          }finally {
            this.isUploading = false; // 提交完成后关闭加载状态
          }
        }
      });
    },
    async handleBeforeUpload(file) {
      this.isUploading = true; // 标识开始上传
      // 这里可以执行你的上传逻辑
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);
        const response = await uploadFileData(formData);

        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ProductManage.uploadSuccess'));
          const fileData = response;
          this.firmwareForm.fileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key, // 文件名
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.firmwareForm.uploadedFile = fileData.key; // 假设 response.key 是文件标识符
          this.firmwareForm.fileSize = fileData.fileSize;
          this.isUploading = false; // 上传成功后关闭加载状态
          return true; // 返回 true 或不返回任何内容，以允许文件上传
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ProductManage.uploadFailed'));
          this.isUploading = false; // 上传失败后关闭加载状态
          return false; // 阻止文件上传
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ProductManage.uploadException'));
        this.isUploading = false; // 异常时关闭加载状态
        return false; // 阻止文件上传
      }
    },
  },
};
</script>

<style scoped>
:deep(.el-upload-list) {
  width: 500px;
}

:deep(.el-upload-list__item-name) {
  width: 300px;
}

:deep(.el-form-item__label ) {
  margin-bottom: 0; /* 减少label下方的外边距 */
  padding-bottom: 0; /* 减少label的内边距 */
}

.data-table {
  margin: 10px 20px 10px 20px; /* 设置默认的左右间隔 */

  @media (max-width: 768px) {
    margin: 5px 10px; /* 在小屏幕下减小间隔 */
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 15px 30px; /* 在中等屏幕下增加间隔 */
  }

}

.table-data {
  width: 100%;
  margin-top: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
