<template>
  <div>
    <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane :label="$t('ProductManage.ConfigManage.faultType')" name="first">
        <SystemSelfCheckAddButton @dataSaved="loadFaultTypeData" :saveMethod="addFaultType"
                                  :dynamicDiagnosisOptions="dynamicDiagnosisOptions" deviceType="0"/>
        <keep-alive>
          <div v-show="activeName === 'first'">
            <MotorFaultSystemSelfCheckTable :tableData="tableData"
                                            @deleteMotorFaultType="loadFaultTypeData"/>
          </div>
        </keep-alive>
        <PaginationBar :total-records="faultTypePagination.totalRecords"
                       :current-page="faultTypePagination.currentPage"
                       :page-size="faultTypePagination.pageSize"
                       @page-change="handleFaultTypePageChange"
                       @page-size-change="handleFaultTypePageSizeChange"/>
      </el-tab-pane>

      <el-tab-pane :label="$t('ProductManage.ConfigManage.faultCode')" name="second">
        <FaultCodeAddButton @dataSaved="loadFaultCodeData" :saveMethod="addFaultCode" deviceType="0"/>

        <keep-alive>
          <div v-show="activeName === 'second'">
            <MotorFaultFaultCodeTable :tableData="faultCodeTableData" @edit-motor="editMotor"
                                      @delete-motor="loadFaultCodeData" device-type="0" @dataSaved="loadFaultCodeData"/>
          </div>
        </keep-alive>
        <PaginationBar :total-records="faultCodePagination.totalRecords"
                       :current-page="faultCodePagination.currentPage"
                       :page-size="faultCodePagination.pageSize"
                       @page-change="handleFaultCodePageChange"
                       @page-size-change="handleFaultCodePageSizeChange"/>
      </el-tab-pane>

    </el-tabs>

  </div>
</template>

<script>
import FaultCodeAddButton
  from '@/views/ProductManage/ConfigManagement/components/Motor/FaultComponents/FaultCodeComponents/AddButton.vue';
import SystemSelfCheckAddButton
  from '@/views/ProductManage/ConfigManagement/components/Motor/FaultComponents/SystemSelfCheckComponents/AddButton.vue';
import MotorFaultSystemSelfCheckTable
  from '@/views/ProductManage/ConfigManagement/components/Motor/FaultComponents/SystemSelfCheckComponents/MotorFaultTable.vue';
import MotorFaultFaultCodeTable
  from '@/views/ProductManage/ConfigManagement/components/Motor/FaultComponents/FaultCodeComponents/MotorFaultTable.vue';
import PaginationBar from '@/components/PaginationBar.vue';
import {addFaultCode, addFaultType, getFaultCodeList, getFaultTypeList} from "@/api/api";
import api from '../../api';

export default {
  data() {
    return {
      tableData: [], // 电机数据
      faultCodeTableData: [],

      faultTypePagination: {
        currentPage: 1,
        pageSize: 10,
        totalRecords: 0,
      },

      faultCodePagination: {
        currentPage: 1,
        pageSize: 10,
        totalRecords: 0,
      },

      activeName: 'first',
      dynamicDiagnosisOptions: [],
    };
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler() {
        this.loadFaultCodeData(); // 初始加载数据
        this.loadFaultTypeData();
      }
    },
  },
  mounted() {
    this.loadFaultCodeData(); // 初始加载数据
    this.loadFaultTypeData();
    this.getDynamicDiagnosisOptions(); // 初始化时获取动态诊断选项
  },
  methods: {
    async getDynamicDiagnosisOptions() {
      this.loading = true;
      try {
        const response = await api.getTestItem({"product_type-eq": 0, "type-eq": 0, page: 1, per_page: 100}); // 假设最多100条数据
        const currentLanguage = this.$i18n.locale; // 获取当前语言
        this.dynamicDiagnosisOptions = response.data.map(item => {
          const matchedDocument = item.documents.find(doc => doc.lang === currentLanguage) || item.documents[0];
          return {
            label: matchedDocument.name,
            value: item.id
          };
        });
      } catch (error) {
        console.error('Error loading dynamic diagnosis options:', error);
        this.$message.error(this.$t('ProductManage.ConfigManage.fetchDataFail'));
      } finally {
        this.loading = false;
      }
    },
    addFaultType(data) {
      addFaultType(data).then(() => {
        this.$message.success(this.$t('ProductManage.ConfigManage.faultTypeAddSuccess'));
        this.loadFaultTypeData();
      }).catch(() => {
        this.$message.error(this.$t('ProductManage.ConfigManage.faultTypeAddFail'));
      });
    },
    addFaultCode(data) {
      addFaultCode(data).then(() => {
        this.$message.success(this.$t('ProductManage.ConfigManage.faultCodeAddSuccess'));
        this.loadFaultCodeData();
      }).catch(() => {
        this.$message.error(this.$t('ProductManage.ConfigManage.faultCodeAddFail'));
      });
    },
    handleClick(tab) {
      if (tab.name === 'second' && this.faultCodeTableData.length === 0) {
        this.loadFaultCodeData(); // 切换到"故障代码"标签时加载数据
      } else if (tab.name === 'first' && this.tableData.length === 0) {
        this.loadFaultTypeData(); // 切换到"故障类型"标签时加载数据
      }
    },
    async handleFaultTypePageChange(newPage) {
      this.faultTypePagination.currentPage = newPage;
      await this.loadFaultTypeData(); // 重新加载数据
    },
    async handleFaultTypePageSizeChange(newPageSize) {
      this.faultTypePagination.pageSize = newPageSize;
      await this.loadFaultTypeData(); // 重新加载数据
    },
    async handleFaultCodePageChange(newPage) {
      this.faultCodePagination.currentPage = newPage;
      await this.loadFaultCodeData(); // 重新加载数据
    },
    async handleFaultCodePageSizeChange(newPageSize) {
      this.faultCodePagination.pageSize = newPageSize;
      await this.loadFaultCodeData(); // 重新加载数据
    },

    async loadFaultTypeData() {
      try {
        const response = await getFaultTypeList(this.faultTypePagination.currentPage, this.faultTypePagination.pageSize, {"device_type-eq": 0});
        const currentLang = this.$i18n.locale; // 获取当前语言
        this.tableData = response.data.map(item => ({
          id: item.id,
          faultCategory: item[`f_${currentLang}`] || item['f_en'], // 如果当前语言的故障类型为空，使用英语
          faultSubCategory: item[`s_${currentLang}`] || item['s_en'], // 如果当前语言的故障子类型为空，使用英语
          hasDynamicDiagnosis: item.dynamic_diagnosis,
          quickDiagnosis: item.faq_url,
        }));
        this.faultTypePagination.totalRecords = response.meta.total;
        this.faultTypePagination.currentPage = response.meta.current_page;
        this.faultTypePagination.pageSize = typeof response.meta.per_page === 'string' ? parseInt(response.meta.per_page, 10) : response.meta.per_page;
      } catch (error) {
        console.error('Error loading fault type data:', error);
      }
    },
    async loadFaultCodeData() {
      try {
        const response = await getFaultCodeList(this.faultCodePagination.currentPage, this.faultCodePagination.pageSize, {"device_type-eq": 0});
        const currentLang = this.$i18n.locale; // 获取当前语言
        this.faultCodeTableData = response.data.map(item => ({
          id: item.id,
          faultName: item[currentLang] || item['en'], // 假设 'zh' 是故障名称
          userManualLink: item.faq_url, // 假设 'faq_url' 是用户手册链接
        }));
        this.faultCodePagination.totalRecords = response.meta.total;
        this.faultCodePagination.currentPage = response.meta.current_page;
        this.faultCodePagination.pageSize = typeof response.meta.per_page === 'string' ? parseInt(response.meta.per_page, 10) : response.meta.per_page;
      } catch (error) {
        console.error('Error loading fault code data:', error);
      }
    },
  },
  components: {
    FaultCodeAddButton,
    SystemSelfCheckAddButton,
    PaginationBar,
    MotorFaultSystemSelfCheckTable,
    MotorFaultFaultCodeTable,
  },

};
</script>
<style scoped>
@import "@/assets/css/publicStyle.css";
</style>
