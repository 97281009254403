<template>
  <div class="add-motor">

    <el-button @click="showDialog" class="button-background-color">
      <el-icon>
        <Plus/>
      </el-icon>&nbsp; {{ $t('ProductManage.ConfigManage.add') }}
    </el-button>
  </div>
  <el-dialog v-model="dialogVisible" :width="1200" :title="$t('ProductManage.ConfigManage.newFaultAdded')"
             style="text-align: left"
  >
    <div class="steps-container">
      <!-- 步骤导航 -->
      <el-steps :active="step" finish-status="success" align-center>
        <el-step :title="$t('ProductManage.ConfigManage.faultCategories')" @click="changeStep(1)"></el-step>
        <el-step :title="$t('ProductManage.ConfigManage.faultSubcategory')" @click="changeStep(2)"></el-step>
        <el-step :title="$t('ProductManage.ConfigManage.hasDynamicDiagnosis')" @click="changeStep(3)"></el-step>
        <el-step :title="$t('ProductManage.ConfigManage.quickDiagnosis')" @click="changeStep(4)"></el-step>
      </el-steps>
    </div>
    <div v-if="step === 1">
      <!-- 步骤1：故障大类 -->
      <el-form ref="step1Form" :model="step1Form" label-width="150px">
        <el-form-item :label="$t('ProductManage.ConfigManage.zh')" prop="categoryCn" required>
          <el-input v-model="step1Form.categoryCn"></el-input>
        </el-form-item>

        <el-form-item :label="$t('ProductManage.ConfigManage.en')" prop="categoryEn" required>
          <el-input v-model="step1Form.categoryEn"></el-input>
        </el-form-item>

        <!-- 其余语言输入框 -->
        <el-form-item v-for="lang in step1Languages" :key="lang.prop" :label="$t(lang.label)" :prop="lang.prop">
          <el-input v-model="step1Form[lang.prop]"></el-input>
        </el-form-item>

      </el-form>
    </div>
    <div v-else-if="step === 2">
      <!-- 步骤2：故障小类 -->
      <el-form ref="step2Form" :model="step2Form" label-width="150px">
        <el-form-item :label="$t('ProductManage.ConfigManage.zh')" prop="subcategoryCn" required>
          <el-input v-model="step2Form.subcategoryCn" :placeholder="$t('ProductManage.ConfigManage.enter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('ProductManage.ConfigManage.en')" prop="subcategoryEn" required>
          <el-input v-model="step2Form.subcategoryEn" :placeholder="$t('ProductManage.ConfigManage.enter')"></el-input>
        </el-form-item>

        <!-- 其余语言输入框 -->
        <el-form-item v-for="lang in step2Languages" :key="lang.prop" :label="$t(lang.label)" :prop="lang.prop">
          <el-input v-model="step2Form[lang.prop]"></el-input>
        </el-form-item>

      </el-form>
    </div>
    <div v-else-if="step === 3">
      <!-- 步骤3：是否关联动态诊断 -->
      <el-form ref="step3Form" :model="step3Form" label-width="150px">
        <el-form-item prop="hasDynamicDiagnosis">
          <label>{{ $t('ProductManage.ConfigManage.hasDynamicDiagnosis') }}：</label>
          <el-select v-model="step3Form.hasDynamicDiagnosis" filterable style="width: 600px;">
            <el-option :label="$t('ProductManage.ConfigManage.yes')" value="1"></el-option>
            <el-option :label="$t('ProductManage.ConfigManage.no')" value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="step3Form.hasDynamicDiagnosis === '1'" prop="diagnosisType">
          <label>{{ $t('ProductManage.ConfigManage.dynamicDiagnosisType') }}：</label>
          <el-select v-model="step3Form.diagnosisType" filterable style="width: 600px;">
            <el-option v-for="option in dynamicDiagnosisOptions" :key="option.value" :label="option.label"
                       :value="option.value"></el-option>
          </el-select>
        </el-form-item>

      </el-form>
    </div>
    <div v-else>
      <!-- 步骤4：快捷诊断 -->
      <el-form ref="step4Form" :model="step4Form" label-width="200px">
        <el-form-item :label="$t('ProductManage.ConfigManage.quickDiagnosis')" prop="quickDiagnosis">
          <el-input v-model="step4Form.quickDiagnosis"
                    :placeholder="$t('ProductManage.ConfigManage.enterQuickDiagnosis')"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="dialog-footer-custom">
      <el-button @click="prevStep" v-if="step > 1" class="custom-reset-button">
        {{ $t('ProductManage.ConfigManage.previousStep') }}
      </el-button>
      <div class="right-buttons">
        <el-button @click="nextStep" v-if="step < 4"
                   :class="{ 'custom-reset-button': isNextValid, 'button-disabled-color': !isNextValid }"
                   :disabled="!isNextValid">{{ $t('ProductManage.ConfigManage.nextStep') }}
        </el-button>
        <el-button
            :class="{ 'button-background-color': isFormValid, 'button-disabled-color': !isFormValid }"
            @click="saveForm" :disabled="!isFormValid" v-else>{{ $t('ProductManage.ConfigManage.complete') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {Plus} from "@element-plus/icons-vue";

export default {
  components: {Plus},
  props: {
    dynamicDiagnosisOptions: {
      type: Array,
      required: true
    },
    saveMethod: {
      type: Function,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      step: 1,
      stepsCompleted: [false, false, false, false], // 初始状态，只有第一步是可点击的

      step1Form: {
        categoryCn: "",
        categoryEn: "",
        categoryIt: "", // 意大利语故障大类
        categoryFr: "", // 法语故障大类
        categoryDe: "", // 德语故障大类
        categoryNl: "", // 荷兰语故障大类
        categoryJa: "", // 日语故障大类
        categorySe: "", // 瑞典语故障大类
        categoryHu: "", // 匈牙利语故障大类
        categoryPl: "", // 波兰语故障大类
        categoryEs: "", // 西班牙语故障大类
        categoryPt: "" // 葡萄牙语故障大类
      },
      step2Form: {
        subcategoryCn: "",
        subcategoryEn: "",
        subcategoryIt: "", // 意大利语故障小类
        subcategoryFr: "", // 法语故障小类
        subcategoryDe: "", // 德语故障小类
        subcategoryNl: "", // 荷兰语故障小类
        subcategoryJa: "", // 日语故障小类
        subcategorySe: "", // 瑞典语故障小类
        subcategoryHu: "", // 匈牙利语故障小类
        subcategoryPl: "", // 波兰语故障小类
        subcategoryEs: "", // 西班牙语故障小类
        subcategoryPt: "" // 葡萄牙语故障小类
      },
      step3Form: {
        hasDynamicDiagnosis: "",
        diagnosisType: "",
      },
      step4Form: {
        quickDiagnosis: "",
      },
      /*categoryOptions: [
        {value: 'option1', label: '选项1'},
        {value: 'option2', label: '选项2'},
        // 更多选项
      ],*/

      step1Languages: [
        {label: 'ProductManage.ConfigManage.it', prop: 'categoryIt'},
        {label: 'ProductManage.ConfigManage.fr', prop: 'categoryFr'},
        {label: 'ProductManage.ConfigManage.de', prop: 'categoryDe'},
        {label: 'ProductManage.ConfigManage.nl', prop: 'categoryNl'},
        {label: 'ProductManage.ConfigManage.ja', prop: 'categoryJa'},
        {label: 'ProductManage.ConfigManage.sv', prop: 'categorySe'},
        {label: 'ProductManage.ConfigManage.hu', prop: 'categoryHu'},
        {label: 'ProductManage.ConfigManage.pl', prop: 'categoryPl'},
        { label: 'ProductManage.ConfigManage.es', prop: 'categoryEs' }, // 添加西班牙语
        { label: 'ProductManage.ConfigManage.pt', prop: 'categoryPt' }  // 添加葡萄牙语
      ],
      step2Languages: [
        {label: 'ProductManage.ConfigManage.it', prop: 'subcategoryIt'},
        {label: 'ProductManage.ConfigManage.fr', prop: 'subcategoryFr'},
        {label: 'ProductManage.ConfigManage.de', prop: 'subcategoryDe'},
        {label: 'ProductManage.ConfigManage.nl', prop: 'subcategoryNl'},
        {label: 'ProductManage.ConfigManage.ja', prop: 'subcategoryJa'},
        {label: 'ProductManage.ConfigManage.sv', prop: 'subcategorySe'},
        {label: 'ProductManage.ConfigManage.hu', prop: 'subcategoryHu'},
        {label: 'ProductManage.ConfigManage.pl', prop: 'subcategoryPl'},
        { label: 'ProductManage.ConfigManage.es', prop: 'subcategoryEs' }, // 添加西班牙语
        { label: 'ProductManage.ConfigManage.pt', prop: 'subcategoryPt' }  // 添加葡萄牙语
      ],
    };
  },
  computed: {
    isFormValid() {
      if (this.step !== 4) {
        return true; // 如果不是第四步，总是有效
      }
      return this.step4Form.quickDiagnosis.trim() !== ''; // 第四步时检查快捷诊断链接是否为空
    },
    isNextValid() {
      if (this.step === 1) {
        // 第一步需要检查的表单项
        return this.step1Form.categoryCn.trim() !== '' && this.step1Form.categoryEn.trim() !== '';
      } else if (this.step === 2) {
        // 第二步需要检查的表单项
        return this.step2Form.subcategoryCn.trim() !== '' && this.step2Form.subcategoryEn.trim() !== '';
      }
      return true; // 对于其他步骤，默认为true
    },
  },

  methods: {
    showDialog() {
      this.dialogVisible = true;
      this.resetForms(); // 重置表单数据
    },
    resetForms() {
      this.step1Form = this.getInitialStep1Form();
      this.step2Form = this.getInitialStep2Form();
      this.step3Form = this.getInitialStep3Form();
      this.step4Form = this.getInitialStep4Form();
      this.step = 1;
      this.stepsCompleted = [false, false, false, false];
    },
    getInitialStep1Form() {
      return {
        categoryCn: "",
        categoryEn: "",
        categoryIt: "", // 意大利语故障大类
        categoryFr: "", // 法语故障大类
        categoryDe: "", // 德语故障大类
        categoryNl: "", // 荷兰语故障大类
        categoryJa: "", // 日语故障大类
        categorySe: "", // 瑞典语故障大类
        categoryHu: "", // 匈牙利语故障大类
        categoryPl: "", // 波兰语故障大类
      };
    },
    getInitialStep2Form() {
      return {
        subcategoryCn: "",
        subcategoryEn: "",
        subcategoryIt: "", // 意大利语故障小类
        subcategoryFr: "", // 法语故障小类
        subcategoryDe: "", // 德语故障小类
        subcategoryNl: "", // 荷兰语故障小类
        subcategoryJa: "", // 日语故障小类
        subcategorySe: "", // 瑞典语故障小类
        subcategoryHu: "", // 匈牙利语故障小类
        subcategoryPl: "", // 波兰语故障小类
      };
    },
    getInitialStep3Form() {
      return {
        hasDynamicDiagnosis: "",
        diagnosisType: "",
      };
    },
    getInitialStep4Form() {
      return {
        quickDiagnosis: "",
      };
    },
    changeStep(targetStep) {
      // 检查所有先前的步骤是否已完成
      for (let i = 0; i < targetStep - 1; i++) {
        if (!this.stepsCompleted[i]) {
          return; // 如果有任何一个步骤未完成，不允许更改步骤
        }
      }
      this.step = targetStep; // 更改步骤
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    nextStep() {
      if (this.step < 4) {
        if (this.validateForm()) {
          this.step++;
        } else {
          this.$message.error(this.$t('ProductManage.ConfigManage.fillRequired'));
        }
      }
    },
    validateForm() {
      if (this.step === 1) {
        return (
            this.step1Form.categoryCn.trim() !== "" &&
            this.step1Form.categoryEn.trim() !== ""
        );
      } else if (this.step === 2) {
        return (
            this.step2Form.subcategoryCn.trim() !== "" &&
            this.step2Form.subcategoryEn.trim() !== ""
        );
      } else if (this.step === 3) {
        return this.step3Form.hasDynamicDiagnosis !== "";
      } else if (this.step === 4) {
        return this.step4Form.quickDiagnosis.trim() !== "";
      }
    },

    saveForm() {
      const data = {
        device_type: this.deviceType,
        f_zh: this.step1Form.categoryCn,
        f_en: this.step1Form.categoryEn,
        s_zh: this.step2Form.subcategoryCn,
        s_en: this.step2Form.subcategoryEn,
        dynamic_diagnosis: this.step3Form.hasDynamicDiagnosis === '1' ? 1 : 0,
        test_item_id: this.step3Form.diagnosisType,
        faq_url: this.step4Form.quickDiagnosis,
        f_it: this.step1Form.categoryIt,
        f_fr: this.step1Form.categoryFr,
        f_de: this.step1Form.categoryDe,
        f_nl: this.step1Form.categoryNl,
        f_ja: this.step1Form.categoryJa,
        f_sv: this.step1Form.categorySe,
        f_hu: this.step1Form.categoryHu,
        f_pl: this.step1Form.categoryPl,
        f_es: this.step1Form.categoryEs, // 添加西班牙语映射
        f_pt: this.step1Form.categoryPt, // 添加葡萄牙语映射
        s_it: this.step2Form.subcategoryIt,
        s_fr: this.step2Form.subcategoryFr,
        s_de: this.step2Form.subcategoryDe,
        s_nl: this.step2Form.subcategoryNl,
        s_ja: this.step2Form.subcategoryJa,
        s_sv: this.step2Form.subcategorySe,
        s_hu: this.step2Form.subcategoryHu,
        s_pl: this.step2Form.subcategoryPl,
        s_es: this.step2Form.subcategoryEs, // 添加西班牙语映射
        s_pt: this.step2Form.subcategoryPt  // 添加葡萄牙语映射
      };
      this.saveMethod(data);
      this.dialogVisible = false;
      this.$emit('dataSaved');
    }
  },
};
</script>

<style scoped>
:deep(.el-upload-list){
  width: 500px;
}
:deep(.el-upload-list__item-name){
  width: 300px;
}
.steps-container {
  margin-bottom: 20px; /* 这里设置你想要的间距大小 */
}

.dialog-footer-custom {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.right-buttons {
  display: flex;
  gap: 10px; /* 按钮之间的间距 */
}

.button-disabled-color, .button-disabled-color:disabled, .button-disabled-color:disabled:hover {
  background-color: rgb(245, 245, 245); /* 灰色背景 */
  color: rgb(195, 195, 195); /* 可以根据需要调整文字颜色 */
  border-color: rgb(217, 217, 217); /* 可以根据需要调整边框颜色 */
  cursor: not-allowed; /* 鼠标样式 */
}

</style>
